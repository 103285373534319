export const skillsetFragment = gql`
    fragment skillsetFragment on SkillsetType {
        id
        uuid
        name
        code
        order
        qualificationRequirements {
            id
            uuid
            validationType
            qualifications {
                id
            }
        }
        createdAt
        updatedAt
    }
`;
