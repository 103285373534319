import type { SkillsetType, SkillsetOrder, SkillsetTypeConnection } from '~/generated/types';
import { skillsetFragment } from '~/graphql/fragments/skillset';

export type GetSkillsetData = {
    skillset: SkillsetType;
}

export type GetSkillsetVariables = {
    id: string | number;
};

export const GET_SKILLSET_QUERY = gql`
    query GetSkillset($id: ID!) {
        skillset(pk: $id) {
            ...skillsetFragment
        }
    }

    ${skillsetFragment}
`;

export type GetSkillsetsData = {
    skillsets: SkillsetTypeConnection;
}

export type GetSkillsetsVariables = {

};

export const GET_SKILLSETS_QUERY = gql`
    query GetSkillsets {
        skillsets {
            edges {
                node {
                    ...skillsetFragment
                }
            }
            
            totalCount

            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }

    ${skillsetFragment}
`;
